import React from "react"
import Layout from "./layout"
import { isLoggedIn, logout } from "../services/auth"
import { navigate } from "gatsby"

const LayoutFull = ({ children }) => {
  return (
    <Layout className={"flex flex-col"}>
      {isLoggedIn() && (
        <header className="flex justify-end items-center">
          <div className="px-4 py-2">
            <a
              href="/"
              onClick={event => {
                event.preventDefault()
                logout(() => navigate(`/portal/login`))
              }}
              className={`text-base leading-5 text-gray-600 hover:text-gray-900`}
            >
              Logout
            </a>
          </div>
        </header>
      )}
      <main className="flex flex-1 items-center justify-center">
        <div className="max-w-lg w-full px-4 py-2">{children}</div>
      </main>
    </Layout>
  )
}

export default LayoutFull
