import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

const Layout = ({ children, className }) => {
  const {
    site: { siteMetadata: {title} },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  return (
    <>
      <Helmet>
        <title>{title} | Homeowner Portal</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={`min-h-screen bg-primary-50 ${className ? className : ''}`}>
        {children}
      </div>
    </>
  )
}

export default Layout
